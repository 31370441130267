import { useEffect, useState } from 'react'
import { TableItem } from '../../components/TableItem'
import { Button } from '../../components/button'
import './style.css'
import { Pagination } from '../../components/Pagination'
import { PopUp } from '../../components/PopUp'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllUsersAction } from '../../Services/action/action'
import { Loading } from '../../components/Loading'
import { Button2 } from '../../components/button/button2'
export const UserList = () => {
    const [data, setData] = useState([])
    const [openPopUp, setOpenPopUp] = useState(false)
    const dispatch = useDispatch()
    const [activeButton, setActiveButton] = useState(0)
    const { GetUserReducer } = useSelector((st) => st)

    useEffect(() => {
        if (openPopUp) {
            document.body.style.overflow = 'hidden'
        }
    }, [openPopUp])


    useEffect(() => {
        setData(GetUserReducer?.data.data)
    }, [GetUserReducer])

    useEffect(() => {
        dispatch(GetAllUsersAction(activeButton))
    }, [activeButton])


    const onSave = async (id) => {
        let token = localStorage.getItem('token')
        console.log(id)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', `Bearer ${token}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            // body: JSON.stringify(data),
        };
        fetch(`https://basrarusbackend.justcode.am/api/admin/get_order_pdf_file?order_id=${id}`, requestOptions)
            .then((r) => r.json())
            .then(r => {
                console.log(r)
                if (r.status) {
                    window.open(r.url, "_blank");
                }
                else {
                    // dispatch(ErrorGetComments())
                }
            })
            .catch((error) => {
                console.log(error)
                // dispatch(ErrorGetComments())
            });
    }
    return <div>
        {openPopUp && <PopUp setOpen={() => setOpenPopUp(false)} />}
        <div className='header'>
            <p>Список пользователей</p>
            <div className='buttonWrapper'>
                <Button onClick={() => setOpenPopUp(true)} text={'PUSH-уведомления'} />
            </div>
        </div>
        {GetUserReducer.loading ?
            <Loading /> :
            <div className='TableWrapper'>
                {
                    data?.map((elm, i) => {
                        console.log(elm)
                        const data = new Date(elm.created_at)
                        return <div onClick={() => window.location = `/UserScreen/${elm.id}`} className='TableItem' >
                            <div >
                                <p className='Tablelabel'>Имя</p>
                                <p className='TablelItem'>{elm.name}</p>
                            </div>
                            <div>
                                <p className='Tablelabel'>Телефон</p>
                                <p className='TablelItem'>{elm.phone}</p>
                            </div>
                            <div>
                                <p className='Tablelabel'>Почта</p>
                                <p className='TablelItem'>{elm.email}</p>
                            </div>
                            <div>
                                <p className='Tablelabel'>Дата рождения</p>
                                <p className='TablelItem'>{elm.date_of_birth}</p>
                            </div>
                            <div>
                                <p className='Tablelabel'>Дата регистрации</p>
                                <p className='TablelItem'>{data.getMonth() + 1}.{data?.getDate()}.{data?.getFullYear()}</p>
                            </div>
                            <div>
                                <p className='Tablelabel'>Заказы</p>
                                <p className='TablelItem'>{elm.order_count}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        }
        {GetUserReducer?.data?.last_page > 1 && <Pagination changeActiveButton={(e) => setActiveButton(e)} length={GetUserReducer?.data?.last_page} activeButton={activeButton} />}
    </div>
}